<!--
 * @Author: Akko
 * @Description: 项目列表
-->
<template>
    <div class="list">
        <div class="program-bar">
            <div class="program-cont">
                <div class="program-title">项目列表</div>
                <div class="explain-word">尊敬的客户，您好!(您共有{{ list.length }}个办理项目，可选择其中一个进行下一步操作)</div>
            </div>
        </div>
        <div v-if="list.length==0" class="no-program">暂无项目</div>
        <div v-else v-loading="isLoading" class="server-cont">
            <div v-for="(item,index) in list" :key="index" class="show-box" @click="goProgramServe({id:item.id,step:item.step,baseSchedule:item.baseSchedule,order_sn:item.orderSn},item)">
                <div class="img-box">
                    <!-- 0为未开始，1为进行中 -->
                    <div class="not-start">{{ item.state==1?"进行中":"未开始" }} (订单号：{{ item.id }})</div>
                    <!-- 优才 -->
                    <el-image v-if="item.username==='优才'||item.username==='小项目-优才'||item.username==='澳门优才'" class="v-image" :src="require('@/assets/img/card_bg.png')">
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>
                    <!-- 专才 -->
                    <el-image v-if="item.username==='专才'" class="v-image" :src="require('@/assets/img/card_bg2.jpg')">
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>
                    <!-- 留学 -->
                    <el-image v-if="item.username==='留学'" class="v-image" :src="require('@/assets/img/card_bg3.jpg')">
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>

                    <!-- 随迁人员 -->
                    <el-image v-if="item.username==='随迁'" class="v-image" :src="require('@/assets/img/card_bg.png')">
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>
                </div>
                <div class="title">
                    <div class="good-serve">{{ item.productName }}</div>
                    <div class="good-serve blue-color">进入</div>
                </div>
            </div>
            <!-- 待删 -->
            <!-- <div class="show-box" @click="goSmall">
                <div class="img-box">
                    <div class="not-start">进行中(订单号：xxxxxxxx)</div>
                    <el-image class="v-image" :src="require('@/assets/img/card_bg.png')">
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>
                </div>
                <div class="title">
                    <div class="good-serve">优才续签补件服务</div>
                    <div class="good-serve blue-color">进入</div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { productList, intoProduct, getProduct, loginToClient } from "@/api/workfow.js";
export default {
    name: "ProjectList",
    data() {
        return {
            list: [],
            isLoading: false,
        };
    },
    created() {
        productList().then((res) => {
            if (res.code == 200) {
                this.list = res.data;
                this.list.forEach((ele) => {
                    getProduct({
                        order_id: ele.id,
                    }).then((ress) => {
                        if (res.code === 200) {
                            ele.username = ress.data.product_id.type;
                        }
                    });
                });
            }
        });
    },
    mounted() {
        localStorage.setItem("isList", 1);
        this.$store.commit("user");
    },
    methods: {
        goSmall() {
            localStorage.setItem("YhorderType", "小项目-优才");
            this.$store.commit("setType");
            this.$router.push({
                name: "ptep4",
                params: {
                    id: 2021840,
                },
            });
        },
        //进入服务
        goProgramServe(data,item) {
            // 后端保存当前记录
            intoProduct({
                orderId: data.id,
            });
            console.log(data)
            //登陆列表页-改变orderId
            let user = JSON.parse(localStorage.getItem("user"));
            user.orderId = data.id;
            user.baseSchedule = data.baseSchedule;//个人信息填写到第几步
            user.hasFinishOnePro = data.step; // 后端返回字段step为整个项目进度0-11，baseSchedule为个人信息6个步骤的进度 0-6
            user.order_sn = data.order_sn
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("isList", 0);
            if (item.productName == "新增随迁人员") {
                data.baseSchedule == 4?localStorage.setItem("stepEnd", 1):localStorage.setItem("stepEnd", 0);
                console.log('aacc',data.baseSchedule == 4, this.$store.state.stepEnd , this.$store.state.isList);
            } else {
                data.baseSchedule == 7
                ? localStorage.setItem("stepEnd", 1)
                : localStorage.setItem("stepEnd", 0);
            }
            this.$store.commit("user");

            // 判断当前是优才/专才/留学
            getProduct({
                order_id: data.id,
            }).then((ress) => {
                if (ress.code === 200) {
                    localStorage.setItem(
                        "YhorderType",
                        ress.data.product_id.type
                    );
                    this.$store.commit("setType");
                    if (data.step == 0) {
                        //个人信息没有填完
                        if (data.baseSchedule == 0) {
                            //刚进入第一步
                            if (ress.data.product_id.type === "优才") {
                                // 获取access_token
                                loginToClient({
                                    order_id: data.id
                                }).then(result => {
                                    if(result.code === 200) {
                                        window.location.href = `${process.env.VUE_APP_USERINFO_URL}/Auth/${result.data.original.access_token}`
                                    }
                                }).finally(() => {
                                    this.isLoading = false
                                })
                                // this.$router.push({
                                //     name: "step1",
                                //     params: {
                                //         id: data.id,
                                //     },
                                // });
                            } else if (ress.data.product_id.type === "专才") {
                                this.$router.push({
                                    name: "etep1",
                                    params: {
                                        id: data.id,
                                    },
                                });
                            } else if (ress.data.product_id.type === "留学") {
                                this.$router.push({
                                    name: "ltep1",
                                    params: {
                                        id: data.id,
                                    },
                                });
                            }else if(ress.data.product_id.type === '小项目-商务'){
                                console.log('小项目-商务', ress.data, data)
                                this.$router.push({
                                    name:
                                        "education1",

                                    params: {
                                        id: data.id,
                                    },
                                });
                            }else {
                                // 跳转新增随迁人员表单
                                if (ress.data.product_id.name == '新增随迁人员') {
                                    // 获取access_token
                                    loginToClient({
                                        order_id: data.id
                                    }).then(result => {
                                        if(result.code === 200) {
                                            window.location.href = `${process.env.VUE_APP_USERINFO_URL}/Auth/${result.data.original.access_token}`
                                        }
                                    }).finally(() => {
                                        this.isLoading = false
                                    })
                                } else {
                                    this.$router.push({
                                        name: "ptep1",
                                        params: {
                                            id: data.id,
                                        },
                                    });
                                }
                            }
                        } else {
                            if (ress.data.product_id.type === "优才") {
                                // 获取access_token
                                loginToClient({
                                    order_id: data.id
                                }).then(result => {
                                    if(result.code === 200) {
                                        window.location.href = `${process.env.VUE_APP_USERINFO_URL}/Auth/${result.data.original.access_token}`
                                    }
                                }).finally(() => {
                                    this.isLoading = false
                                })
                                // this.$router.push({
                                //     name: "step" + data.baseSchedule,
                                //     params: {
                                //         id: data.id,
                                //     },
                                // });
                            } else if (ress.data.product_id.type === "专才") {
                                this.$router.push({
                                    name:
                                        "etep" +
                                        (Number(data.baseSchedule) + 1),
                                    params: {
                                        id: data.id,
                                    },
                                });
                            } else if (ress.data.product_id.type === "留学") {
                                this.$router.push({
                                    name: "ltep" + data.baseSchedule,
                                    params: {
                                        id: data.id,
                                    },
                                });
                            } else {
                                // 跳转新增随迁人员表单
                                if (ress.data.product_id.name == '新增随迁人员') {
                                    // 获取access_token
                                    loginToClient({
                                        order_id: data.id
                                    }).then(result => {
                                        if(result.code === 200) {
                                            window.location.href = `${process.env.VUE_APP_USERINFO_URL}/Auth/${result.data.original.access_token}`
                                        }
                                    }).finally(() => {
                                        this.isLoading = false
                                    })
                                } else {
                                    // 小项目优才
                                    const smallProductIds = [29, 55, 30, 32, 96] // 这五类产品走新逻辑，其他继续走以前逻辑
                                    if (smallProductIds.includes(ress.data.product_id.id)) {
                                        this.$router.push({
                                            name: `SmallStep${Number(data.baseSchedule) + 1}`,
                                            params: {
                                                id: data.id,
                                            },
                                            query: {
                                                id: ress.data.product_id.id // 产品ID
                                            }
                                        });
                                    } else {
                                        this.$router.push({
                                            name: "ptep" + data.baseSchedule + 1,
                                            params: {
                                                id: data.id,
                                            },
                                        })
                                    }
                                }
                            }
                        }
                    } else {
                        //个人信息7步已经填完
                        this.$router.push({
                            path: "/HandleProgress",
                        });
                    }
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.list {
    width: 100%;
    min-height: 750px;
}

.program-bar {
    width: 100%;
    height: 130px;
    background: linear-gradient(313deg, #74cdff 0%, #409eff 100%);
}

.program-cont {
    width: 1000px;
    height: 100%;
    margin: 0 auto;

    .program-title {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        padding-top: 28px;
        padding-bottom: 12px;
        letter-spacing: 1px;
    }

    .explain-word {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
    }
}

.server-cont {
    width: 1000px;
    height: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
}

.good-serve {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
}

.blue-color {
    color: #409eff;
}

.show-box {
    width: 300px;
    height: 212px;
    cursor: pointer;
    margin: 0 36px 36px 0;
    transition: all 0.2s ease-in-out;

    &:nth-child(3n) {
        margin-right: 0;
    }

    &:hover {
        transform: translateY(-3px);
    }

    .img-box {
        width: 100%;
        height: 172px;
        box-sizing: border-box;
        position: relative;
    }

    .title {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
    }

    box-shadow: 0px 10px 20px #dcdfe6;
}

.v-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
}

.not-start {
    position: absolute;
    top: 0px;
    z-index: 11;
    margin-left: 16px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
}

.no-program {
    font-size: 20px;
    font-weight: 500;
    width: 1000px;
    margin: 0 auto;
    margin-top: 50px;
}
.image-slot {
    text-align: center;
    color: #999;
    line-height: 175px;
}
</style>
